// import fetch from '../../libs/fetch';
import {
    COMMON_SERVER_HEADERS,
    NEXT_APP_FINANCE_API_INVALIDATION_MINUTES,
    NEXT_APP_SERVER_FETCH_CACHE_ENABLED,
    RETAIL_BACKEND,
} from '@/constants';
import {
    IMinAndMaxInterestRatesResult,
    IMinAndMaxPricesResult,
    InterestRate,
    Lender,
} from '@/types';
import { TIME_IN_SECONDS } from '@/constants/time';
import To from '@/libs/to-promise';
import pino from 'pino';
const logger = pino().child({ s: 'src/api-requests/car-finance/index.ts' });

export async function getInterestRates(): Promise<InterestRate[]> {
    const [err, res] = await To(
        fetch(`${RETAIL_BACKEND}/interest-rates`, {
            headers: {
                ...COMMON_SERVER_HEADERS,
            },
            next: {
                revalidate:
                    TIME_IN_SECONDS.minute *
                    Number(NEXT_APP_FINANCE_API_INVALIDATION_MINUTES) *
                    Number(NEXT_APP_SERVER_FETCH_CACHE_ENABLED),
            },
        })
    );
    if (err) {
        logger.error(err);
        return [] as InterestRate[];
    }
    const [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        logger.info('Error while trying to parse   getInterestRates response.');
        logger.info('original data provided is ', data);
        logger.error(dataParseError);
        return [] as InterestRate[];
    }
    return data;
}

export async function getMinAndMaxCarPrices(): Promise<IMinAndMaxPricesResult> {
    const [err, res] = await To(
        fetch(`${RETAIL_BACKEND}/vehicles/minMaxPrices`, {
            headers: { ...COMMON_SERVER_HEADERS },
            next: {
                revalidate:
                    TIME_IN_SECONDS.minute *
                    Number(NEXT_APP_FINANCE_API_INVALIDATION_MINUTES) *
                    Number(NEXT_APP_SERVER_FETCH_CACHE_ENABLED),
            },
        })
    );
    if (err) {
        logger.error(err);
        return {} as IMinAndMaxPricesResult;
    }
    const [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        logger.info(
            'Error while trying to parse getMinAndMaxCarPrices response.'
        );
        logger.info('original data provided is ', data);
        logger.error(dataParseError);
        return {} as IMinAndMaxPricesResult;
    }
    return data;
}
export async function getLenders(): Promise<Lender[]> {
    const [err, res] = await To(
        fetch(`${RETAIL_BACKEND}/lenders/public`, {
            headers: {
                ...COMMON_SERVER_HEADERS,
            },
            next: {
                revalidate:
                    TIME_IN_SECONDS.minute *
                    Number(NEXT_APP_FINANCE_API_INVALIDATION_MINUTES) *
                    Number(NEXT_APP_SERVER_FETCH_CACHE_ENABLED),
            },
        })
    );
    if (err) {
        logger.error(err);
        return [] as Lender[];
    }
    const [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        logger.info('Error while trying to parse   getLenders response.');
        logger.info('original data provided is ', data);
        logger.error(dataParseError);
        return [] as Lender[];
    }
    return data.data;
}
export async function getMinAndMaxInterestRates(): Promise<IMinAndMaxInterestRatesResult> {
    const [err, res] = await To(
        fetch(`${RETAIL_BACKEND}/interest-rates/range`, {
            headers: {
                ...COMMON_SERVER_HEADERS,
            },
            next: {
                revalidate:
                    TIME_IN_SECONDS.minute *
                    Number(NEXT_APP_FINANCE_API_INVALIDATION_MINUTES) *
                    Number(NEXT_APP_SERVER_FETCH_CACHE_ENABLED),
            },
        })
    );
    if (err) {
        logger.error(err);
        return {} as IMinAndMaxInterestRatesResult;
    }
    const [dataParseError, data] = await To(res.json());
    if (dataParseError) {
        logger.info(
            'Error while trying to parse   getMinAndMaxInterestRates response.'
        );
        logger.info('original data provided is ', data);
        logger.error(dataParseError);
        return {} as IMinAndMaxInterestRatesResult;
    }
    return data;
}
